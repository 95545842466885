import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter.js";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import * as options from "../labels/options_en";
import { getBranches } from "../common/getFunctions";
import { Row, Col, Card, Modal } from "react-bootstrap";
import bikerImg from "../images/biker.jpg";
import TestimonialModal from "../commonComponents/TestimonialModal";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import DeleteUserModal from "../commonComponents/DeleteUserModal";
import BranchePullDown from "../commonComponents/BranchePullDown";
import commonFunctions from "../common/Functions";
import UserCycleExport from "../dialogs/UserCycleDialog.js";
import ExpandableCard from "../common/ExpandableCard .js";

const Users = () => {
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState();
  const [typeFilter, setTypeFilter] = useState("");
  const [subTypeFilter, setSubTypeFilter] = useState("");
  const [keywordFilter, setKeywordFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState(true);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [disabledFilter, setDisabledFilter] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonialModal, setTestimonialModal] = useState(false);
  const [selectedUserByTestimonial, setSelectedUserByTestimonial] =
    useState("");
  const [selectedUserForDelete, setSelectedUserForDelete] = useState("");
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedUserForDeleteInfo, setSelectedUserForDeleteInfo] =
    useState("");

  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [expandedCards, setExpandedCards] = useState({});

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      await getUsers("", "", "", page, itemsPerPage, "");
    }

    onReady();
  }, []);

  const resetSearch = async () => {
    setBranchFilter("");
    setTypeFilter("");
    setSubTypeFilter("");
    setKeywordFilter("");
    await getUsers("", "", "", page, itemsPerPage, "", "");
  };

  const getUsers = async (
    branchFilter,
    typeFilter,
    keywordFilter,
    page,
    itemsPerPage,
    disabledFilter,
    subTypeFilter
  ) => {
    setLoading(true);
    UserService.getUsersList(
      branchFilter,
      typeFilter,
      keywordFilter,
      (page - 1) * itemsPerPage,
      itemsPerPage,
      false,
      disabledFilter,
      "",
      "",
      "",
      "",
      "",
      subTypeFilter
    ).then(
      (response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.list
        ) {
          let data = response.data.data;
          setUsers(data.list);
          setTotal(data ? data.total : 0);
          setCount(Math.ceil(data.total / itemsPerPage));
          setResetMsg("");
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const deleteUser = async () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      UserService.deleteUser(selectedUserForDelete)
        .then((data) => {
          alert("User deleted!");
          let oldList = users;
          var data = oldList.filter(function (obj) {
            return obj.id !== selectedUserForDelete;
          });
          setUsers(data);
          setDeleteUserModal(false);
        })
        .catch(function (error) {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        });
    }
  };

  const triggerSearch = async () => {
    setPage(1); // return to first page
    await getUsers(
      branchFilter,
      typeFilter,
      keywordFilter,
      1,
      itemsPerPage,
      disabledFilter,
      subTypeFilter
    );
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    resetResults();
  };
  const handleTypeChange = async (val) => {
    setTypeFilter(val);
    resetResults();
  };
  const handleSubTypeChange = async (val) => {
    setSubTypeFilter(val);
    resetResults();
  };

  const handleDisabledChange = async (val) => {
    setDisabledFilter(val);
    resetResults();
  };
  const handleKeywordChange = async (val, triggerSearch) => {
    setKeywordFilter(val);
    resetResults();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };

  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setUsers([]);
    setResetMsg(labels.RESET_MSG);
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await getUsers(
      branchFilter,
      typeFilter,
      keywordFilter,
      value,
      itemsPerPage,
      disabledFilter,
      subTypeFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await getUsers(
      branchFilter,
      typeFilter,
      keywordFilter,
      1,
      newItemsPerPage,
      disabledFilter,
      subTypeFilter
    );
  };

  const disableEnableUser = async (event, id) => {
    var statusLabel = "disabled";
    var disabled = true;
    var disableTerm = "disable";

    if (event.target.className !== "fa fa-ban") {
      disableTerm = "enable";
    }

    if (
      window.confirm("Are you sure you want to " + disableTerm + " this user?")
    ) {
      if (event.target.className === "fa fa-ban") {
        event.target.className = "fa fa-circle red";
        event.target.title = "Enable User";
        event.target.alt = "Enable User";
      } else {
        event.target.className = "fa fa-ban";
        event.target.title = "Disable User";
        event.target.alt = "Disable User";
        statusLabel = "enabled";
        disabled = false;
      }
      UserService.disableEnable(id, disabled).then(
        (response) => {
          alert("User has been " + statusLabel + ".");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(resMessage);
        }
      );
    }
  };

  const handleTestimonialModalShow = (id, test) => {
    setTestimonial(test);
    setSelectedUserByTestimonial(id);
    setTestimonialModal(true);
  };

  const handleDeleteUserModalShow = (id, u) => {
    setSelectedUserForDelete(id);
    setSelectedUserForDeleteInfo(u);
    setDeleteUserModal(true);
  };

  // Handles individual card toggle
  const onToggleCard = (id, expanded) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [id]: expanded,
    }));
  };

  // Expand all cards
  const handleExpandAll = () => {
    const newExpandedState = users.reduce((acc, user) => {
      acc[user.id] = true; // Set all cards to expanded
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to expanded for all
  };

  // Collapse all cards
  const handleCollapseAll = () => {
    const newExpandedState = users.reduce((acc, user) => {
      acc[user.id] = false; // Set all cards to collapsed
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to collapsed for all
  };
  const renderUserCard = (u) => {
    const isExpanded = expandedCards[u.id] || false;

    const header = (
      <>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {/* Header row with expand icon, name, and InternalNoteComponent */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {u.picture ? (
                <img
                  style={{
                    width: 50,
                    height: 50,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                  src={`${process.env.REACT_APP_SERVER_API}/upload/profiles/${u.picture}`}
                  alt="Profile"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = bikerImg;
                  }}
                />
              ) : (
                <img
                  style={{
                    float: "right",
                    width: 50,
                    height: 50,
                    marginRight: 10,
                  }}
                  src={bikerImg}
                  alt="logo"
                />
              )}
              <span>
                {u.first_name} {u.last_name}
              </span>
            </div>

            {/* InternalNoteComponent aligned to the right */}
            <InternalNoteComponent
              list={users}
              setList={setUsers}
              oldInternalNote={u.internalNote}
              selectedUserByNote={u.id}
              clickable
            />
          </div>
        </div>
      </>
    );
    const body = (
      <>
        <NavLink to={"/user/edit/" + u.id} className="text-dark midText">
          <i
            alt="Edit User"
            title="Edit User"
            className="fa fa-pen"
            aria-hidden="true"
          ></i>
        </NavLink>
        &nbsp;&nbsp;
        <NavLink to={"/user/" + u.id} className="text-dark midText">
          <i
            alt="View User"
            title="View User"
            className="fa fa-binoculars"
            aria-hidden="true"
          ></i>
        </NavLink>
        &nbsp;&nbsp;
        {(currentUser.type == "admin" || u.type != "admin") && (
          <>
            <a href="#">
              {!u.disabled ? (
                <i
                  onClick={(e) => disableEnableUser(e, u.id)}
                  alt="Disable User"
                  title="Disable User"
                  className="fa fa-ban"
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  onClick={(e) => disableEnableUser(e, u.id)}
                  alt="Enable User"
                  title="Enable User"
                  className="fa fa-circle red"
                  aria-hidden="true"
                ></i>
              )}
            </a>
          </>
        )}
        {u.type !== "admin" && u.type !== "subscriber" ? (
          <>
            &nbsp;&nbsp;
            <i
              role="button"
              onClick={() => handleDeleteUserModalShow(u._id, u)}
              alt="Delete User"
              title="Delete User"
              className="fa fa-trash"
              aria-hidden="true"
            ></i>
          </>
        ) : (
          ""
        )}
        {u.cardId ? (
          <>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_SERVER_API}/upload/cardIds/${u.cardId}`}
              className="greenLink mx-0"
            >
              Identity Card
            </a>
          </>
        ) : (
          ""
        )}
        {u.cardId2 ? (
          <>
            <br />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_SERVER_API}/upload/cardIds/${u.cardId2}`}
              className="greenLink mx-0"
            >
              Identity Card 2
            </a>
          </>
        ) : (
          ""
        )}
        <br />
        {u.type === "subscriber" || u.type === "registered" ? (
          <>
            <button
              className="linkBtn greenLink mx-0"
              onClick={() => handleTestimonialModalShow(u.id, u.testimonial)}
            >
              Testimonial
            </button>
            <br />
          </>
        ) : (
          ""
        )}
        <span className="midText">{u.email}</span>
        <br />
        <span className="midText">
          {u.phone[0] === "0" && u.phone[1] === "0" ? (
            <span>+{u.phone.slice(2)}</span>
          ) : u.phone[0] != "+" ? (
            <span>
              <span>+</span>
              {u.phone[0] !== "9" &&
              u.phone[1] !== "6" &&
              u.phone[2] !== "1" ? (
                <span>961</span>
              ) : null}
              <span>{u.phone}</span>
            </span>
          ) : (
            <span>{u.phone}</span>
          )}
        </span>
        <br />
        <span className="midText">{u.gender ? u.gender : "Not set"}</span>
        <span className="midText">
          {u.birthday ? (
            <span> - {DateFormatter.getAge(u.birthday)} years old</span>
          ) : null}
        </span>
        {" - "}
        <span className="midText">{u.type}</span>
        {u.type === "registered" || u.type === "subscriber" ? (
          <>
            {" - "}{" "}
            <span className="midText">
              {u.membership == false ? "short term" : "membership"}
            </span>
          </>
        ) : (
          ""
        )}
        <br />
        {u.buildingName === "" ||
        u.floor === "" ||
        u.street === "" ||
        u.town === "" ? null : (
          <>
            <span className="midText">
              {u.buildingName +
                " building, " +
                u.floor +
                " floor, " +
                u.street +
                ", " +
                u.town}
            </span>
            <br />
          </>
        )}
        <span className="midText">
          {u.verifiedphone ? (
            <span style={{ color: "green" }}>Phone verified</span>
          ) : (
            <span style={{ color: "red" }}>Phone not verified</span>
          )}
        </span>
        <br />
        <span className="midText">
          {u.verifiedemail ? (
            <span style={{ color: "green" }}>Email verified</span>
          ) : (
            <span style={{ color: "red" }}>Email not verified</span>
          )}
        </span>
        <br />
        {u.type === "registered" || u.type === "subscriber" ? (
          <>
            <span className="midText">
              {u.iagree ? (
                <span style={{ color: "green" }}>iAgree checked</span>
              ) : (
                <span style={{ color: "red" }}>iAgree not checked</span>
              )}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        <span className="tinyCardLabel">User Balance:&nbsp;</span>
        <span className="midText">
          {commonFunctions.CommaFormatted(u.userBalance)} USD
        </span>
        <br />
        <span className="tinyCardLabel">Created at:&nbsp;</span>
        <span className="midText">
          {DateFormatter.DateFormatterOne(u.createdAt)}
        </span>
        <br />
        {u.type === "registered" || u.type === "subscriber" ? (
          <>
            <span className="tinyCardLabel">Source:&nbsp;</span>
            <span className="midText">{u.source ? u.source : "Unknown"}</span>
            <br />
          </>
        ) : (
          ""
        )}
        {currentUser.type == "admin" || currentUser.type === "manager" ? (
          <>
            <span className="tinyCardLabel">Branch:&nbsp;</span>
            <span className="midText">{u.branch ? u.branch.label : ""}</span>
            <br />
          </>
        ) : (
          ""
        )}
        {u.company && u.company.name ? (
          <>
            <span className="tinyCardLabel">Company:&nbsp;</span>
            <span className="midText">{u.company.name}</span>
            <br />
          </>
        ) : (
          ""
        )}
      </>
    );

    return (
      <ExpandableCard
        header={header}
        body={body}
        onToggleCard={onToggleCard}
        isExpanded={isExpanded}
        id={u.id}
      />
    );
  };

  return (
    <div className="container">
      <DeleteUserModal
        deleteUserModal={deleteUserModal}
        setDeleteUserModal={setDeleteUserModal}
        list={users}
        setList={setUsers}
        selectedUserForDelete={selectedUserForDelete}
        setSelectedUserForDelete={setSelectedUserForDelete}
        firstName={selectedUserForDeleteInfo.first_name}
        lastName={selectedUserForDeleteInfo.last_name}
        email={selectedUserForDeleteInfo.email}
        phone={selectedUserForDeleteInfo.phone}
        isUsersList
      />
      <TestimonialModal
        testimonialModal={testimonialModal}
        setTestimonialModal={setTestimonialModal}
        selectedUserByTestimonial={selectedUserByTestimonial}
        setSelectedUserByTestimonial={setSelectedUserByTestimonial}
        list={users}
        setList={setUsers}
        oldTestimonial={testimonial}
      />
      {currentUser.type === "admin" || currentUser.type === "manager" ? (
        <>
          <div className="floatRight ">
            <NavLink to={"/user/new"} className="greenLink mx-0">
              Add User
            </NavLink>
          </div>
          <div className="floatRight ">
            <UserCycleExport />
          </div>
        </>
      ) : (
        ""
      )}

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}

      <div>
        <h3> Users List </h3>
        <div className="d-flex flex-row">
          {currentUser && currentUser.type === "admin" ? (
            <Col lg={2} className="p-0">
              <BranchePullDown
                branch={branchFilter}
                labelInside={false}
                forceBlank={true}
                setBranch={handleBranchChange}
                branches={branches}
              />
            </Col>
          ) : null}
          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="sourceFilter">
              Client
            </label>
            <input
              type="text"
              className="form-control filterInput2"
              name="keywordFilter"
              value={keywordFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleKeywordChange(e.target.value)}
            />
          </Col>

          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="typeFilter">
              Type
            </label>
            <select
              className="form-control filterInput2"
              name="typeFilter"
              placeholder="User Type"
              value={typeFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleTypeChange(e.target.value)}
            >
              <option value="">All</option>
              {options &&
                options.user_types.map((item, index) => (
                  <option key={"category" + index} value={item.key}>
                    {item.value}
                  </option>
                ))}
            </select>
          </Col>

          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="subTypeFilter">
              Subscription Type
            </label>
            <select
              className="form-control filterInput2"
              name="subTypeFilter"
              placeholder="Subscription Type"
              value={subTypeFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleSubTypeChange(e.target.value)}
            >
              <option value="">All</option>
              {options &&
                options.userSubscribeType.map((item, index) => (
                  <option key={"subType" + index} value={item.key}>
                    {item.value}
                  </option>
                ))}
            </select>
          </Col>

          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="disabled">
              Status
            </label>
            <select
              className="form-control filterInput2"
              name="disabled"
              value={disabledFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleDisabledChange(e.target.value)}
            >
              <option value="">All</option>
              <option value="no">Enabled</option>
              <option value="yes">Disabled</option>
            </select>
          </Col>
          <table border="0">
            <tr>
              <td>
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary btn-block search-button "
                >
                  Go
                </button>
              </td>
              <td>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary btn-block reset-button "
                >
                  Reset
                </button>
              </td>
            </tr>
          </table>
        </div>
        {!error && !loading && users.length !== 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
                <span style={{ float: "left", marginRight: 10 }}>
                  <a
                    className="greenLink mx-0"
                    href="javascript:void(0)"
                    onClick={handleExpandAll}
                  >
                    Expand All
                  </a>{" "}
                  <a
                    className="greenLink mx-0"
                    href="javascript:void(0)"
                    onClick={handleCollapseAll}
                  >
                    Collapse All
                  </a>
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {users.map((u) => renderUserCard(u))}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </div>
  );
};

export default Users;
