import React, { useState, useEffect } from "react";
import SubscriptionService from "../services/subscription.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter";
import {
  getBranches,
  getSubscriptionsList,
  getPlansList,
  getCategories,
} from "../common/getFunctions";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import { viewPDF } from "../common/pdf";
import Switch from "react-switch";
import Pagination from "@material-ui/lab/Pagination";
import SubscriptionsByUser from "../commonComponents/SubscriptionsByUser";
import { Card, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Functions from "../common/Functions.js";
import TestimonialModal from "../commonComponents/TestimonialModal";
import TerminationModal from "../commonComponents/TerminationModal";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import InternalNoteModal from "../commonComponents/InternalNoteModal";
import * as options from "../labels/options_en";
import ReturnPackageModal from "../commonComponents/ReturnPackageModal";
import UnfreezeSubscriptionButton from "../commonComponents/UnfreezeSubscriptionButton";
import ForceTerminate from "../commonComponents/ForceTerminate.jsx";
import ExpandableCard from "../common/ExpandableCard .js";

const currentUser = JSON.parse(localStorage.getItem("user")) || null;

const currentDate = DateFormatter.getCurrentDate();

const Subscriptions = (props) => {
  const [error, setError] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [branchFilter, setBranchFilter] = useState();

  const [keywordFilter, setKeywordFilter] = useState();
  const [noInvoiceFilter, setNoInvoiceFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState("");
  //const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [terminateSubscriptionModal, setTerminateSubscriptionModal] =
    useState(false);
  const [reason, setReason] = useState("");
  const [selectedUserByReason, setSelectedUserByReason] = useState("");
  const [nextRenewalFilter, setNextRenewalFilter] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonialModal, setTestimonialModal] = useState(false);
  const [selectedUserByTestimonial, setSelectedUserByTestimonial] =
    useState("");
  const [internalNoteModal, setInternalNoteModal] = useState(false);
  const [selectedUserByNote, setSelectedUserByNote] = useState("");
  const [oldInternalNote, setOldInternalNote] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [planFilter, setPlanFilter] = useState("");
  const [saveSearch, setSaveSearch] = useState(true);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [returnPackageModal, setReturnPackageModal] = useState(false);
  const [
    selectedSubscriptionForReturnPackage,
    setSelectedSubscriptionForReturnPackage,
  ] = useState("");

  const [sourceFilter, setSourceFilter] = useState("");
  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [expandedCards, setExpandedCards] = useState({});
  const [categories, setCategories] = useState([]);
  const [terminationCategory, setTerminationCategory] = useState("");
  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      // setBranches(await getBranches());
      //setBranchFilter(currentUser.branch)
      //setPlansList(await getPlansList(currentUser.branch));
      if (
        currentUser.type === "registered" ||
        currentUser.type === "subscriber"
      )
        await listSubsciptions(
          currentUser._id,
          "",
          currentUser.branch,
          "",
          page,
          itemsPerPage,
          "",
          "",
          "",
          "",
          "",
          ""
        );
      else await setFilters();
    }

    onReady();
  }, []);

  async function setFilters() {
    setBranches(await getBranches());

    let data = null;
    let saveSearchT = localStorage.getItem("subscriptionSaveSearch") || false;
    saveSearchT = saveSearchT && saveSearchT === "true" ? true : false;
    if (saveSearchT) {
      let filters = localStorage.getItem("subscriptionFilters") || null;
      data = filters ? JSON.parse(filters) : "";
    }
    setSaveSearch(saveSearchT);
    const branchFilterT =
      data &&
      data.branch &&
      data.branch !== "undefined" &&
      data.branch != "null"
        ? data.branch
        : currentUser.branch;
    const planFilterT =
      data && data.planFilter && data.planFilter !== "undefined"
        ? data.planFilter
        : "";
    const keywordFilterT =
      data && data.keywordFilter && data.keywordFilter !== "undefined"
        ? data.keywordFilter
        : "";
    const nextRenewalFilterT =
      data && data.nextRenewalFilter && data.nextRenewalFilter != "undefined"
        ? new Date(data.nextRenewalFilter)
        : "";
    const statusFilterT =
      data && data.statusFilter && data.statusFilter != "undefined"
        ? data.statusFilter
        : "";
    const noInvoiceFilterT =
      data && data.noInvoiceFilter != "undefined"
        ? data.noInvoiceFilter
        : false;
    const startT =
      data && data.start && data.start != "undefined"
        ? new Date(data.start)
        : "";
    const endT =
      data && data.end && data.end != "undefined" ? new Date(data.end) : "";
    const sourceFilterT =
      data && data.sourceFilter && data.sourceFilter != "undefined"
        ? data.sourceFilter
        : "";
    if (saveSearchT) {
      setPlansList(await getPlansList(branchFilterT));
      setBranchFilter(branchFilterT);
      setPlanFilter(planFilterT);
      setKeywordFilter(keywordFilterT);
      setNextRenewalFilter(nextRenewalFilterT);
      setStatusFilter(statusFilterT);
      setNoInvoiceFilter(noInvoiceFilterT);
      setStart(startT);
      setEnd(endT);
      setSourceFilter(sourceFilterT);
      setCategories(await getCategories(branchFilterT, "termination"));
    } else {
      setPlansList(await getPlansList(currentUser.branch));
      setCategories(await getCategories(currentUser.branch, "termination"));
    }
    listSubsciptions(
      "",
      noInvoiceFilterT,
      branchFilterT,
      statusFilterT,
      page,
      itemsPerPage,
      keywordFilterT,
      nextRenewalFilterT,
      planFilterT,
      startT,
      endT,
      sourceFilterT
    );
  }

  const listSubsciptions = async (
    bikerId,
    noInvoiceFilter,
    branchFilter,
    statusFilter,
    page,
    itemsPerPage,
    keywordFilter,
    nextRenewalFilter,
    planFilter,
    start,
    end,
    sourceFilter
  ) => {
    setLoading(true);
    let data = await getSubscriptionsList(
      bikerId,
      noInvoiceFilter,
      branchFilter,
      parseInt(page - 1) * itemsPerPage,
      itemsPerPage,
      "",
      keywordFilter,
      nextRenewalFilter,
      statusFilter,
      planFilter,
      start,
      end,
      sourceFilter
    );
    setSubscriptions(data.list);
    setStatusFilter(statusFilter);
    setTotal(data ? data.total : 0);
    setCount(Math.ceil(data.total / itemsPerPage));
    setResetMsg("");

    if (saveSearch)
      localStorage.setItem(
        "subscriptionFilters",
        JSON.stringify({
          branch: branchFilter,
          keywordFilter,
          planFilter,
          nextRenewalFilter,
          statusFilter,
          noInvoiceFilter,
          start,
          end,
        })
      );

    setLoading(false);
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await listSubsciptions(
      "",
      noInvoiceFilter,
      branchFilter,
      statusFilter,
      value,
      itemsPerPage,
      keywordFilter,
      nextRenewalFilter,
      planFilter,
      start,
      end,
      sourceFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await listSubsciptions(
      "",
      noInvoiceFilter,
      branchFilter,
      statusFilter,
      1,
      newItemsPerPage,
      keywordFilter,
      nextRenewalFilter,
      planFilter,
      start,
      end,
      sourceFilter
    );
  };

  const triggerSearch = async () => {
    setPage(1); // return to first page
    await listSubsciptions(
      "",
      noInvoiceFilter,
      branchFilter,
      statusFilter,
      1,
      itemsPerPage,
      keywordFilter,
      nextRenewalFilter,
      planFilter,
      start,
      end,
      sourceFilter
    );
  };

  const resetSearch = async () => {
    setBranchFilter(currentUser.branch);
    const cats = await getCategories(currentUser.branch, "termination");

    setCategories(cats);
    setPlanFilter("");
    setStatusFilter("");
    setKeywordFilter("");
    setNoInvoiceFilter(false);
    setNextRenewalFilter("");
    setStart("");
    setEnd("");
    setSourceFilter("");
    await setPlansList(await getPlansList(currentUser.branch));
    await listSubsciptions(
      "",
      "",
      "",
      "",
      page,
      itemsPerPage,
      "",
      "",
      "",
      "",
      "",
      ""
    );
  };

  const createInvoice = async (id, isRenewal) => {
    if (
      window.confirm(
        "Are you sure you want to create an invoice for this subscription?"
      )
    ) {
      SubscriptionService.createInvoice(id, isRenewal).then(
        (res) => {
          alert("A draft invoice is created!");

          if (res.data && res.data.data) {
            props.history.push(
              "/invoice/edit/" + res.data.data + "?subscription"
            );
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  const deleteSubscription = async (id) => {
    if (window.confirm("Are you sure you want to delete this Subscription?")) {
      SubscriptionService.deleteSubscription(id).then(
        () => {
          alert("Subscription deleted!");
          setSubscriptions(
            subscriptions.filter((subscription) => subscription._id !== id)
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    setCategories(await getCategories(bid, "termination"));
    // in this case we need to get the new list of bikers.
    setPlansList(await getPlansList(bid));
    resetResults();
  };
  const handlePlanChange = async (id) => {
    setPlanFilter(id);
    resetResults();
  };

  const handleStatusChange = async (s) => {
    setStatusFilter(s);
    resetResults();
  };

  const setInvoiceSwitchFilter = () => {
    setNoInvoiceFilter(!noInvoiceFilter);
    resetResults();
  };

  const handleKeywordChange = (val) => {
    setKeywordFilter(val);
    resetResults();
  };
  const resetResults = () => {
    setTotal(0);
    setCount(0);
    localStorage.setItem("subscriptionFilters", "");
    setSubscriptions([]);
    setResetMsg(labels.RESET_MSG);
  };
  const handleReasonModalShow = (id) => {
    //var reasonByUser = waitings.find((user) => user._id === id);
    //setComment(commentByUser.waitingNote)
    setSelectedUserByReason(id);

    setTerminateSubscriptionModal(true);
  };
  const handleTerminateSubscriptionModalClose = () => {
    setSelectedUserByReason("");
    setReason("");
    setTerminationCategory("");
    setTerminateSubscriptionModal(false);
  };
  const sendReasonHandler = () => {
    if (!terminationCategory && !reason) {
      alert("Terminate reason is required!");
      return;
    }

    var data = {
      id: selectedUserByReason,
      terminateReason: reason,
      terminationCategory: terminationCategory,
    };

    SubscriptionService.terminateSubscription(data).then(async ({ data }) => {
      setSubscriptions(
        subscriptions.map((s, i) => {
          if (s.id === selectedUserByReason) {
            s.terminated = true;
            s.terminateDate = currentDate;
            s.lastDate =
              data && data.data && data.data.lastDate ? data.data.lastDate : "";
            s.nextRenewal =
              data && data.data && data.data.nextRenewal
                ? data.data.nextRenewal
                : "";
          }
          return s;
        })
      );
    });

    handleTerminateSubscriptionModalClose();
  };

  const activateSubscription = (id) => {
    if (
      window.confirm(
        "Are you sure you want to activate this subscription again?"
      )
    ) {
      SubscriptionService.activateSubscription(id).then(
        (res) => {
          alert("Subscription Activated!");
          setSubscriptions(
            subscriptions.map((s, i) => {
              if (s.id === id) {
                s.terminated = false;
                s.lastDate = "";
                s.nextRenewal =
                  res.data && res.data.data && res.data.data.nextRenewal
                    ? res.data.data.nextRenewal
                    : "";
              }
              return s;
            })
          );
        },
        (error) => {
          console.log(error);
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };

  const handleTestimonialModalShow = (id, test) => {
    setTestimonial(test);
    setSelectedUserByTestimonial(id);
    setTestimonialModal(true);
  };

  const handleAddInternalNoteClick = (id, internalNote) => {
    setSelectedUserByNote(id);
    setOldInternalNote(internalNote);
    setInternalNoteModal(true);
  };

  const handleSaveSearchChange = () => {
    localStorage.setItem("subscriptionSaveSearch", (!saveSearch).toString());
    setSaveSearch(!saveSearch);
  };

  const handleReturnPackageClick = (id, planType) => {
    if (planType == "" || planType == "monthly") {
      setSelectedSubscriptionForReturnPackage(id);
      setReturnPackageModal(true);
    } else {
      if (window.confirm("Are you sure you want to return this package?")) {
        SubscriptionService.markPackageAsReturned(id, false).then((res) => {
          alert(
            "Package has been marked as returned. Please make sure to return the Identiy ID for the user!"
          );

          setSubscriptions(
            subscriptions.map((s, i) => {
              if (s && s.id === id) {
                s.packageReturned = true;
                s.packageReturnedDate = DateFormatter.getCurrentDate();
                s.isFrozen = false;
              }
              return s;
            })
          );
        });
      }
    }
  };

  if (currentUser.type === "registered" || currentUser.type === "subscriber") {
    return (
      <SubscriptionsByUser
        userId={currentUser._id}
        list={subscriptions}
        setSubscriptionList={setSubscriptions}
        userFullName={currentUser.full_name}
      />
    );
  }

  const renderFilters = () => {
    return (
      <>
        <div className="filterContainer flex-column">
          <div className="d-flex flex-row">
            <Col lg={2} className="p-0">
              <label className="filterLabelInput" htmlFor="type">
                Client
              </label>
              <input
                type="text"
                className="form-control filterInput2"
                name="keywordFilter"
                value={keywordFilter}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleKeywordChange(e.target.value)}
              />
            </Col>
            <Col lg={2} className="p-0">
              <label className="filterLabelInput" htmlFor="nextRenewal">
                Next Renewal
              </label>
              <DatePicker
                autoComplete="off"
                dateFormat="dd-MM-yyyy"
                className="form-control filterInput2"
                name="nextRenewal"
                selected={nextRenewalFilter}
                onChange={(date) => setNextRenewalFilter(date)}
              />
            </Col>
            <Col lg={2} className="p-0">
              <label className="filterLabelInput" htmlFor="statusFilter">
                Status
              </label>
              <select
                className="form-control filterInput2"
                value={statusFilter}
                onChange={(e) => handleStatusChange(e.target.value)}
              >
                {options.subscribeFilters.map((s, index) => (
                  <option key={index} value={s.key}>
                    {s.value}
                  </option>
                ))}
              </select>
            </Col>
            <Col lg={2} className="p-0">
              <label className="filterLabelInput" htmlFor="sourceFilter">
                Source
              </label>
              <select
                className="form-control filterInput2"
                value={sourceFilter}
                onChange={(e) => setSourceFilter(e.target.value)}
              >
                <option value="" key="0">
                  All
                </option>
                {options.source_register.map((s, index) => (
                  <option key={index} value={s.key}>
                    {s.value}
                  </option>
                ))}
              </select>
            </Col>

            <Col lg={2} className="p-0">
              <div style={{ marginTop: 30 }} border="0">
                <div className="d-flex flex-row align-items-start">
                  <button
                    onClick={(e) => triggerSearch(e)}
                    className="btn btn-primary search-button"
                  >
                    Go
                  </button>
                  <button
                    onClick={(e) => resetSearch(e)}
                    className="btn btn-primary reset-button"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={3} style={{ paddingTop: 30 }}>
              <button
                className="linkBtn greenLink"
                onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
              >
                {showAdvancedFilters ? "Hide" : "Show"} Advanced
              </button>
              <div className="d-flex flex-row align-items-center">
                <input
                  onChange={handleSaveSearchChange}
                  style={{ width: "auto", margin: "5px" }}
                  type="checkbox"
                  checked={saveSearch}
                />
                <label
                  style={{
                    width: "auto",
                    margin: "0px",
                    padding: "0px",
                    color: "#343a40",
                    fontSize: 14,
                  }}
                >
                  Save Search
                </label>
              </div>
            </Col>
          </div>
          {showAdvancedFilters ? (
            <div className="d-flex flex-row">
              <Col lg={2} className="p-0">
                <label htmlFor="start" className="filterLabelInput">
                  From
                </label>
                <DatePicker
                  autoComplete="off"
                  dateFormat="dd-MM-yyyy"
                  className="form-control filterInput2"
                  name="start"
                  selected={start}
                  onChange={(date) => setStart(date)}
                />
              </Col>
              <Col lg={2} className="p-0">
                <label htmlFor="to" className="filterLabelInput">
                  To
                </label>
                <DatePicker
                  autoComplete="off"
                  dateFormat="dd-MM-yyyy"
                  className="form-control filterInput2"
                  name="end"
                  selected={end}
                  onChange={(date) => setEnd(date)}
                />
              </Col>
              <Col lg={2} className="p-0">
                <label className="filterLabelInput" htmlFor="branch">
                  Plan
                </label>

                <select
                  className="form-control filterInput2"
                  value={planFilter}
                  onChange={(e) => handlePlanChange(e.target.value)}
                >
                  <option value="" key="0">
                    All
                  </option>
                  {plansList.map((p, index) => (
                    <option key={index} value={p._id}>
                      {p.label}
                    </option>
                  ))}
                </select>
              </Col>
              {currentUser && currentUser.type === "admin" ? (
                <Col lg={2} className="p-0">
                  <label className="filterLabelInput" htmlFor="branch">
                    Branch
                  </label>

                  <select
                    className="form-control filterInput2"
                    value={branchFilter}
                    onChange={(e) => handleBranchChange(e.target.value)}
                  >
                    <option value="" key="0">
                      All
                    </option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch._id}>
                        {branch.label}
                      </option>
                    ))}
                  </select>
                </Col>
              ) : null}
              <Col lg={2} className="p-0">
                <label className="filterLabelInput" htmlFor="hasInvoice">
                  No Invoice?
                </label>
                <Switch
                  className="filterInput"
                  onChange={setInvoiceSwitchFilter}
                  checked={noInvoiceFilter}
                />
              </Col>
            </div>
          ) : (
            ""
          )}
        </div>
      </>
    );
  };
  // Handles individual card toggle
  const onToggleCard = (id, expanded) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [id]: expanded,
    }));
  };

  // Expand all cards
  const handleExpandAll = () => {
    const newExpandedState = subscriptions.reduce((acc, user) => {
      acc[user.id] = true; // Set all cards to expanded
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to expanded for all
  };

  // Collapse all cards
  const handleCollapseAll = () => {
    const newExpandedState = subscriptions.reduce((acc, user) => {
      acc[user.id] = false; // Set all cards to collapsed
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to collapsed for all
  };

  const renderUserCard = (u) => {
    const isExpanded = expandedCards[u.id] || false;
    const rentedAccessories = u.accessories
      ? u.accessories.filter(
          (acc) => acc.usage === "Rent" || acc.usage === 0 || acc.usage === "0"
        )
      : [];

    const rentedAccessoriesCount = rentedAccessories.length;
    const accessoriesList = rentedAccessories.map((acc, index, array) => {
      const comma = index < array.length - 1 ? ", " : "";
      return (
        <span key={index}>
          {acc.inventoryLabel}
          {comma}
        </span>
      );
    });
    const header = (
      <>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {/* Header row with expand icon, name, and InternalNoteComponent */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {u.user ? (
                <NavLink
                  className="text-dark"
                  target="_blank"
                  to={"/user/" + u.user._id}
                >
                  {u.user.full_name}
                </NavLink>
              ) : (
                ""
              )}

              <NavLink
                to={"/subscription/" + u._id}
                className="text-dark midText"
                style={{ marginLeft: "10px" }}
              >
                <i alt="View" title="View" className="fa fa-binoculars"></i>
              </NavLink>

              <>
                &nbsp;&nbsp;
                <NavLink
                  to={"subscription/edit/" + u._id}
                  className="text-dark midText"
                  style={{ marginLeft: "5px" }}
                >
                  <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                </NavLink>
              </>

              {currentUser &&
              (currentUser.type === "admin" ||
                currentUser.type === "manager") ? (
                u.invoice ? null : (
                  <>
                    &nbsp;&nbsp;
                    <a
                      href="#!"
                      className="text-dark midText"
                      onClick={() => deleteSubscription(u._id)}
                    >
                      <i
                        alt="Delete"
                        title="Delete"
                        className="fa fa-trash"
                      ></i>
                    </a>
                  </>
                )
              ) : null}
            </div>
            {/* InternalNoteComponent aligned to the right */}
            <InternalNoteComponent
              list={subscriptions}
              setList={setSubscriptions}
              oldInternalNote={u.user ? u.user.internalNote : ""}
              selectedUserByNote={u.user ? u.user._id : ""}
              clickable={false}
              note={u.note || ""}
              rentedAcc={u.rentedAcc || ""}
            />
          </div>
        </div>
      </>
    );
    const body = (
      <>
        {!u.draft && !u.packageReturned ? (
          <>
            <button
              className="linkBtn red text-dark small-link midText"
              onClick={() => createInvoice(u._id, u.invoice ? true : false)}
            >
              <i
                alt="Create Invoice"
                title="Create Invoice"
                className="fa fa-file-invoice"
              >
                {" "}
                <span className="faText">Create Invoice</span>
              </i>
            </button>
            <br />
          </>
        ) : null}
        {u.draft ? (
          ""
        ) : u.terminated && u.packageReturned !== true ? (
          <>
            <button
              className="linkBtn red text-dark small-link midText"
              onClick={() => activateSubscription(u._id)}
            >
              <i className="fa fa-plug">
                {" "}
                <span className="faText">Activate Subscription</span>
              </i>
            </button>
            <br />
          </>
        ) : u.invoice &&
          !u.terminated &&
          (!u.plan || !u.plan.planType || u.plan.planType == "monthly") ? (
          <>
            <button
              className="linkBtn red text-dark small-link midText"
              onClick={() => handleReasonModalShow(u._id)}
            >
              <i className="fa fa-power-off">
                {" "}
                <span className="faText">Terminate Subscription</span>
              </i>
            </button>
            <br />
          </>
        ) : null}

        {(u.terminated ||
          (u.plan &&
            (u.plan.planType == "daily" ||
              u.plan.planType == "weekly" ||
              u.plan.planType == "hourly"))) &&
        u.packageReturned !== true ? (
          <>
            {" "}
            <button
              className="linkBtn text-dark small-link midText"
              onClick={() =>
                handleReturnPackageClick(u._id, u.plan ? u.plan.planType : "")
              }
            >
              <i className="fa fa-box">
                {" "}
                <span className="faText">Return Package</span>
              </i>
            </button>
            <br />
          </>
        ) : null}
        {u.isFrozen ? (
          <>
            {" "}
            <UnfreezeSubscriptionButton
              id={u._id}
              name={u.user ? u.user.full_name : "Unknown"}
            />
            <br />{" "}
            <ForceTerminate
              id={u._id}
              name={u.user ? u.user.full_name : "Unknown"}
              list={subscriptions}
              setList={setSubscriptions}
            />
            <br />
          </>
        ) : (
          ""
        )}
        <button
          className="linkBtn greenLink mx-0"
          onClick={() =>
            handleTestimonialModalShow(u.user, u.user ? u.user.testimonial : "")
          }
        >
          <i className="fa fa-message">
            {" "}
            <span className="faText">Testimonial</span>
          </i>
        </button>
        <br />
        <button
          className="linkBtn greenLink mx-0"
          onClick={() =>
            handleAddInternalNoteClick(
              u.user ? u.user._id : "",
              u.user ? u.user.internalNote : ""
            )
          }
        >
          <i className="fa fa-comment">
            {" "}
            <span className="faText">Add an internal note</span>
          </i>
        </button>
        <br />

        {u.terminated && u.packageReturned !== true && u.terminateDate ? (
          <>
            <span className="tinyCardLabel">Terminate Request:&nbsp;</span>
            <span className="midText">
              {DateFormatter.DateFormatterOne(u.terminateDate)}{" "}
            </span>
            <br />
          </>
        ) : (
          ""
        )}

        <span className="tinyCardLabel">Plan:&nbsp;</span>
        <span className="midText">{u.plan ? u.plan.label : ""}</span>
        <br />
        <span className="tinyCardLabel">Start:&nbsp;</span>
        <span className="midText">
          {DateFormatter.DateFormatterOne(u.start)}
        </span>
        <br />
        {u.plan &&
        (u.plan.planType === "daily" || u.plan.planType === "hourly") &&
        u.startTime ? (
          <>
            <span className="tinyCardLabel">Time:&nbsp;</span>
            <span className="midText">{u.startTime}</span>
            <br />
          </>
        ) : (
          ""
        )}

        {!u.packageReturned &&
        u.nextRenewal &&
        u.nextRenewal !== "None" &&
        u.nextRenewal !== "" ? (
          <>
            <span className="tinyCardLabel">Next Renewal:&nbsp;</span>
            <span className="midText">
              {DateFormatter.DateFormatterOne(u.nextRenewal)}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        {rentedAccessoriesCount > 0 && (
          <>
            <span className="tinyCardLabel">
              Rented Accessories{" "}
              <span className="red bold">({rentedAccessoriesCount})</span>
              :&nbsp;
            </span>
            <span className="midText red">{accessoriesList}</span>
            <br />
          </>
        )}

        {!u.packageReturned &&
        u.lastDate &&
        u.lastDate !== "None" &&
        u.lastDate !== "" ? (
          <span className="red">
            <span className="tinyCardLabel">Last Date:&nbsp;</span>
            <span className="midText">
              {DateFormatter.DateFormatterOne(u.lastDate)}
            </span>
            <br />
          </span>
        ) : (
          ""
        )}

        <span className="tinyCardLabel">Bike #:&nbsp;</span>
        <span className="midText">{u.bike ? u.bike.number : ""}</span>
        <br />
        <span className="tinyCardLabel">Invoice:&nbsp;</span>
        <span className="midText">
          {u.invoice ? (
            <>
              <button
                onClick={() => viewPDF(u.invoice._id, u.invoice.fileName)}
                className="linkBtn text-dark "
              >
                {u.invoice.number}
              </button>
              {/*&nbsp;&nbsp;<NavLink target="_blank" to={"/invoice/edit/" + u.invoice._id}><i alt="Edit Invoice" title="Edit Invoice" className="fa fa-pen"></i></NavLink>*/}
            </>
          ) : (
            "None"
          )}
        </span>
        <br />
        {u && u.invoice ? (
          <>
            <span className="tinyCardLabel">Total Fee Origin:&nbsp;</span>
            <span className="midText">
              {Functions.CommaFormatted(u.invoice.final_amount * 1) +
                " " +
                u.invoice.currency}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        {u && u.user ? (
          <>
            <span className="tinyCardLabel">Source:&nbsp;</span>
            <span className="midText">{u.user.source}</span>
            <br />
          </>
        ) : (
          ""
        )}

        <span className="tinyCardLabel">Status:&nbsp;</span>
        <span className="midText">
          {u.packageReturned ? (
            <span style={{ color: "red" }}>
              Package {u.isFrozen ? "Frozen" : "Returned"}{" "}
              {u.packageReturnedDate
                ? " at " + DateFormatter.DateFormatterOne(u.packageReturnedDate)
                : ""}
            </span>
          ) : u.terminated ? (
            <span style={{ color: "red" }}>Terminated</span>
          ) : u.draft ? (
            <span style={{ color: "red" }}>Draft</span>
          ) : !u.invoice ? (
            <span style={{ color: "green" }}> Active - no invoice</span>
          ) : u.invoice.sent && !u.invoice.paid ? (
            <span style={{ color: "green" }}>Invoice Sent - not paid</span>
          ) : u.invoice.paid ? (
            <span style={{ color: "green" }}>Invoice Paid</span>
          ) : (
            <span style={{ color: "red" }}>No Invoice</span>
          )}
        </span>
      </>
    );
    return (
      <ExpandableCard
        header={header}
        body={body}
        onToggleCard={onToggleCard}
        isExpanded={isExpanded}
        id={u.id}
      />
    );
  };

  return (
    <>
      <ReturnPackageModal
        returnPackageModal={returnPackageModal}
        setReturnPackageModal={setReturnPackageModal}
        selectedSubscriptionForReturnPackage={
          selectedSubscriptionForReturnPackage
        }
        list={subscriptions}
        setList={setSubscriptions}
      />
      <InternalNoteModal
        internalNoteModal={internalNoteModal}
        setInternalNoteModal={setInternalNoteModal}
        selectedUserByNote={selectedUserByNote}
        oldInternalNote={oldInternalNote}
        list={subscriptions}
        setList={setSubscriptions}
        isSubscription
      />
      <TestimonialModal
        testimonialModal={testimonialModal}
        setTestimonialModal={setTestimonialModal}
        selectedUserByTestimonial={selectedUserByTestimonial}
        setSelectedUserByTestimonial={setSelectedUserByTestimonial}
        list={subscriptions}
        setList={setSubscriptions}
        oldTestimonial={testimonial}
        type="subscriptions"
      />
      <TerminationModal
        terminateSubscriptionModal={terminateSubscriptionModal}
        handleTerminateSubscriptionModalClose={
          handleTerminateSubscriptionModalClose
        }
        terminationCategory={terminationCategory}
        setTerminationCategory={setTerminationCategory}
        categories={categories}
        reason={reason}
        setReason={setReason}
        sendReasonHandler={sendReasonHandler}
      />

      <div className="container">
        {currentUser.type === "admin" ||
        currentUser.type === "manager" ||
        currentUser.type === "helpdesk" ? (
          <div className="floatRight ">
            <NavLink to={"/subscription/new"} className="greenLink mx-0">
              Add Subscription
            </NavLink>
          </div>
        ) : (
          ""
        )}

        <div>
          <h3> Subscriptions List </h3>
          {renderFilters()}
        </div>
        {error ? (
          <header className="jumbotron">
            {" "}
            <h3>{error}</h3>{" "}
          </header>
        ) : null}

        {!error && !loading && subscriptions.length !== 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
                <span style={{ float: "left", marginRight: 10 }}>
                  <a
                    className="greenLink mx-0"
                    href="javascript:void(0)"
                    onClick={handleExpandAll}
                  >
                    Expand All
                  </a>{" "}
                  <a
                    className="greenLink mx-0"
                    href="javascript:void(0)"
                    onClick={handleCollapseAll}
                  >
                    Collapse All
                  </a>
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {subscriptions.map((u) => renderUserCard(u))}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </>
  );
};

export default Subscriptions;
