import React from "react";
import { Card, Col } from "react-bootstrap";

const ExpandableCard = ({ header, body, onToggleCard, isExpanded, id }) => {
  const handleToggle = () => {
    onToggleCard(id, !isExpanded); // Use the passed in isExpanded prop to toggle state
  };

  return (
    <Col style={{ float: "left", marginLeft: 10, width: 300 }}>
      <Card
        style={{
          marginLeft: 0,
          width: "290px",
          minHeight: isExpanded ? "350px" : "100px", // Adjust height based on expanded state
          paddingLeft: 10,
          align: "top",
          transition: "min-height 0.3s ease", // Optional: Smooth transition for height change
        }}
      >
        <Card.Body>
          <Card.Title
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {isExpanded ? (
              <i
                onClick={handleToggle}
                alt="Collapse"
                title="Collapse"
                className="fa fa-chevron-up"
                aria-hidden="true"
              ></i>
            ) : (
              <i
                onClick={handleToggle}
                alt="Expand"
                title="Expand"
                className="fa fa-chevron-down"
                aria-hidden="true"
              ></i>
            )}

            {header}
          </Card.Title>
          {isExpanded && body}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ExpandableCard;
