import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getPackagesList = (
  branch,
  number,
  packageName,
  offset,
  limit,
  onlyList
) => {
  return axios.get(
    API_URL +
      "/package/list?branch=" +
      branch +
      "&number=" +
      number +
      "&keyword=" +
      packageName +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&onlyList=" +
      onlyList,
    { headers: authHeader() }
  );
};

const deletePackage = (id) => {
  return axios(API_URL + "/package/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const postPackage = (data) => {
  return axios(API_URL + "/package/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const getPackageById = (id) => {
  return axios.get(API_URL + "/package/" + id, { headers: authHeader() });
};
const getPackageDetailsById = (id) => {
  return axios.get(API_URL + "/package/details/" + id, {
    headers: authHeader(),
  });
};

const savePackageInvoice = (data) => {
  return axios(API_URL + "/package/invoice/save", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const markPackageAsReturned = (id) => {
  return axios(API_URL + "/package/return", {
    method: "post",
    headers: authHeader(),
    data: { id },
  });
};
const activateSubscription = (id) => {
  return axios(API_URL + "/package/activate", {
    method: "post",
    headers: authHeader(),
    data: { id },
  });
};
const terminateSubscription = (data) => {
  return axios(API_URL + "/package/terminate", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

const updateInternalNote = (data) => {
  return axios(API_URL + "/package/note", {
    method: "post",
    headers: authHeader(),
    data: data,
  });
};

export default {
  terminateSubscription,
  markPackageAsReturned,
  activateSubscription,
  postPackage,
  deletePackage,
  getPackagesList,
  getPackageById,
  getPackageDetailsById,
  savePackageInvoice,
  updateInternalNote,
};
