import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_SERVER_API + "/api";

const getInventoriesList = (
  onlyAccessories,
  invType,
  branch,
  offset,
  limit,
  onlyList,
  label,
  model,
  archived
) => {
  return axios.get(
    API_URL +
      "/inventory/list?invType=" +
      invType +
      "&onlyAccessories=" +
      onlyAccessories +
      "&branch=" +
      branch +
      "&onlyList=" +
      onlyList +
      "&offset=" +
      offset +
      "&limit=" +
      limit +
      "&label=" +
      label +
      "&model=" +
      model +
      "&archived=" +
      archived,
    { headers: authHeader() }
  );
};

const deleteInventory = (id) => {
  return axios(API_URL + "/inventory/" + id, {
    method: "delete",
    headers: authHeader(),
  });
};

const archiveInventory = (id) => {
  return axios(API_URL + "/archive/inventory/" + id, {
    method: "put",
    headers: authHeader(),
  });
};
const putInventory = (
  id,
  number,
  label,
  type,
  description,
  branch,
  total_in_use,
  total_in_repair,
  total_in_stock,
  total_in_recycle,
  total_in_obs,
  isAccessory,
  rentPrice,
  sellPrice,
  currency,
  VAT,
  originalPrice,
  size,
  color,
  MOQ,
  supplier,
  unit,
  total_lost
) => {
  return axios(API_URL + "/inventory/save", {
    method: "post",
    headers: authHeader(),
    data: {
      _id: id,
      number: number,
      label: label,
      type: type,
      description: description,
      branch: branch,
      total_in_use: total_in_use,
      total_in_repair: total_in_repair,
      total_in_stock: total_in_stock,
      total_in_recycle: total_in_recycle,
      total_in_obs: total_in_obs,
      isAccessory: isAccessory,
      rentPrice: rentPrice,
      sellPrice: sellPrice,
      currency: currency,
      VAT: VAT,
      originalPrice: originalPrice,
      size: size,
      color: color,
      MOQ: MOQ,
      supplier: supplier,
      unit: unit,
      total_lost: total_lost,
    },
  });
};

const postInventory = (
  number,
  label,
  type,
  description,
  branch,
  total_in_use,
  total_in_repair,
  total_in_stock,
  total_in_recycle,
  total_in_obs,
  isAccessory,
  rentPrice,
  sellPrice,
  currency,
  VAT,
  originalPrice,
  size,
  color,
  MOQ,
  supplier,
  unit,
  total_lost
) => {
  return axios(API_URL + "/inventory/save", {
    method: "post",
    headers: authHeader(),
    data: {
      _id: "",
      number: number,
      label: label,
      type: type,
      description: description,
      branch: branch,
      total_in_use: total_in_use,
      total_in_repair: total_in_repair,
      total_in_stock: total_in_stock,
      total_in_recycle: total_in_recycle,
      total_in_obs: total_in_obs,
      isAccessory: isAccessory,
      rentPrice: rentPrice,
      sellPrice: sellPrice,
      currency: currency,
      VAT: VAT,
      originalPrice: originalPrice,
      size: size,
      color: color,
      MOQ: MOQ,
      supplier: supplier,
      unit: unit,
      total_lost: total_lost,
    },
  });
};

const getInventoryById = (id) => {
  return axios.get(API_URL + "/inventory/" + id, { headers: authHeader() });
};

export default {
  putInventory,
  postInventory,
  deleteInventory,
  getInventoriesList,
  getInventoryById,
  archiveInventory,
};
