import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFormatter from "../common/DateFromatter.js";
import Pagination from "@material-ui/lab/Pagination";
import Loader from "../commonComponents/Loader";
import * as labels from "../labels/en";
import {
  getBranches,
  getCategories,
  getPlansList,
} from "../common/getFunctions";
import { viewPDF } from "../common/pdf";
import { Card, Row, Col, Modal } from "react-bootstrap";
import commonFunctions from "../common/Functions";
import SubscriptionService from "../services/subscription.service";
import TestimonialModal from "../commonComponents/TestimonialModal";
import InternalNoteComponent from "../commonComponents/InternalNoteComponent";
import InternalNoteModal from "../commonComponents/InternalNoteModal";
import * as options from "../labels/options_en";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReturnPackageModal from "../commonComponents/ReturnPackageModal";
import UnfreezeSubscriptionButton from "../commonComponents/UnfreezeSubscriptionButton";
import TerminationModal from "../commonComponents/TerminationModal";
import ForceTerminate from "../commonComponents/ForceTerminate.jsx";
import ExpandableCard from "../common/ExpandableCard .js";

const Clients = (props) => {
  const [error, setError] = useState("");
  const [clients, setClients] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [branches, setBranches] = useState([]);
  const [branchFilter, setBranchFilter] = useState(currentUser.branch);
  const [keywordFilter, setKeywordFilter] = useState("");
  const [planFilter, setPlanFilter] = useState("");
  const [subTypeFilter, setSubTypeFilter] = useState("membership");
  const [loading, setLoading] = useState(true);
  const [resetMsg, setResetMsg] = useState(true);
  const [planList, setPlanList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [terminateSubscriptionModal, setTerminateSubscriptionModal] =
    useState(false);
  const [reason, setReason] = useState("");
  const [selectedUserByReason, setSelectedUserByReason] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonialModal, setTestimonialModal] = useState(false);
  const [selectedUserByTestimonial, setSelectedUserByTestimonial] =
    useState("");
  const [internalNoteModal, setInternalNoteModal] = useState(false);
  const [selectedUserByNote, setSelectedUserByNote] = useState("");
  const [oldInternalNote, setOldInternalNote] = useState("");
  const [sourceFilter, setSourceFilter] = useState("");
  const [invFilter, setInvFilter] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [returnPackageModal, setReturnPackageModal] = useState(false);
  const [
    selectedSubscriptionForReturnPackage,
    setSelectedSubscriptionForReturnPackage,
  ] = useState("");
  const [categories, setCategories] = useState([]);
  const [terminationCategory, setTerminationCategory] = useState("");

  const pageSizes = [9, 18, 24, 30, 36, 60];
  const [expandedCards, setExpandedCards] = useState({});

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      setBranches(await getBranches());
      setCategories(await getCategories(currentUser.branch, "termination"));
      setPlanList(await getPlansList(currentUser.branch));
      await getUsers(
        currentUser.branch,
        "",
        page,
        itemsPerPage,
        "",
        "",
        "",
        "membership",
        ""
      );
    }

    onReady();
  }, []);

  const resetSearch = async () => {
    setBranchFilter(currentUser.branch);
    setPlanList(await getPlansList(currentUser.branch));
    setPlanFilter("");
    setSubTypeFilter("");
    setKeywordFilter("");
    setInvFilter("");
    setSourceFilter("");
    setCategories(await getCategories(currentUser.branch, "termination"));
    await getUsers(
      currentUser.branch,
      "",
      page,
      itemsPerPage,
      "",
      "",
      "",
      "membership",
      ""
    );
  };

  const getUsers = async (
    branchFilter,
    keywordFilter,
    page,
    itemsPerPage,
    sourceFilter,
    start,
    end,
    subTypeFilter,
    planFilter,
    invFilter
  ) => {
    setLoading(true);
    UserService.getUsersList(
      branchFilter,
      "subscriber",
      keywordFilter,
      (page - 1) * itemsPerPage,
      itemsPerPage,
      true,
      "",
      "",
      "",
      sourceFilter,
      start,
      end,
      subTypeFilter,
      planFilter,
      invFilter
    ).then(
      (response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.list
        ) {
          let data = response.data.data;
          setClients(data.list);
          setTotal(data ? data.total : 0);
          setCount(Math.ceil(data.total / itemsPerPage));
          setResetMsg("");
          setLoading(false);
        }
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  const triggerSearch = async () => {
    setPage(1); // return to first page
    await getUsers(
      branchFilter,
      keywordFilter,
      1,
      itemsPerPage,
      sourceFilter,
      start,
      end,
      subTypeFilter,
      planFilter,
      invFilter
    );
  };

  const handleBranchChange = async (bid) => {
    setBranchFilter(bid);
    setCategories(await getCategories(currentUser.branch, "termination"));
    setPlanList(await getPlansList(currentUser.branch));
    resetResults();
  };

  const handleKeywordChange = async (val, triggerSearch) => {
    setKeywordFilter(val);
    resetResults();
  };
  const handlePlanChange = async (val, triggerSearch) => {
    setPlanFilter(val);
    resetResults();
  };
  const handleSourceChange = async (val, triggerSearch) => {
    setSourceFilter(val);
    resetResults();
  };

  const handleInvChange = async (val) => {
    setInvFilter(val);
    resetResults();
  };

  const handleSubTypeChange = (val, triggerSearch) => {
    setSubTypeFilter(val);
    resetResults();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      triggerSearch(e);
    }
  };

  const resetResults = () => {
    setTotal(0);
    setCount(0);
    setClients([]);
    setResetMsg(labels.RESET_MSG);
  };
  const handlePageChange = async (event, value) => {
    setPage(value);
    await getUsers(
      branchFilter,
      keywordFilter,
      value,
      itemsPerPage,
      sourceFilter,
      start,
      end,
      subTypeFilter,
      planFilter,
      invFilter
    );
  };

  const handlePageSizeChange = async (newItemsPerPage) => {
    setitemsPerPage(newItemsPerPage);
    setPage(1);
    await getUsers(
      branchFilter,
      keywordFilter,
      1,
      newItemsPerPage,
      sourceFilter,
      start,
      end,
      subTypeFilter,
      planFilter,
      invFilter
    );
  };

  const createInvoice = async (id, isRenewal) => {
    if (
      window.confirm(
        "Are you sure you want to create an invoice for this subscription?"
      )
    ) {
      SubscriptionService.createInvoice(id, isRenewal).then(
        (res) => {
          alert("A draft invoice is created!");

          if (res.data && res.data.data) {
            props.history.push("/invoice/edit/" + res.data.data);
          }
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          alert(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };
  /*
  const deleteSubscription = async (id) => {

    if (window.confirm("Are you sure you want to delete this Subscription?")) {
      SubscriptionService.deleteSubscription(id).then(
        () => {

          alert("Subscription deleted!");
          setClients(clients.filter(client => client.usersubscribe._id !== id));

        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");

          }
        }
      );
    }
  }
*/
  const handleReasonModalShow = (id) => {
    //var reasonByUser = waitings.find((user) => user._id === id);
    //setComment(commentByUser.waitingNote)
    setSelectedUserByReason(id);

    setTerminateSubscriptionModal(true);
  };
  const handleTerminateSubscriptionModalClose = () => {
    setSelectedUserByReason("");
    setReason("");
    setTerminationCategory("");
    setTerminateSubscriptionModal(false);
  };
  const sendReasonHandler = () => {
    if (!terminationCategory && !reason) {
      alert("Terminate reason is required!");
      return;
    }

    var data = {
      id: selectedUserByReason,
      terminateReason: reason,
      terminationCategory: terminationCategory,
    };

    SubscriptionService.terminateSubscription(data).then(async ({ data }) => {
      setClients(
        clients.map((c, i) => {
          if (c.usersubscribe && c.usersubscribe.id === selectedUserByReason) {
            c.usersubscribe.terminated = true;
            c.usersubscribe.lastDate =
              data && data.data && data.data.lastDate ? data.data.lastDate : "";
            c.usersubscribe.nextRenewal =
              data && data.data && data.data.nextRenewal
                ? data.data.nextRenewal
                : "";
          }
          return c;
        })
      );
    });

    handleTerminateSubscriptionModalClose();
  };

  const activateSubscription = (id) => {
    if (
      window.confirm(
        "Are you sure you want to activate this subscription again?"
      )
    ) {
      SubscriptionService.activateSubscription(id).then(
        (res) => {
          alert("Subscription Activated!");
          setClients(
            clients.map((c, i) => {
              console.log("c:", c);
              if (c.usersubscribe && c.usersubscribe.id === id) {
                c.usersubscribe.terminated = false;
                c.usersubscribe.lastDate = "";
                c.usersubscribe.nextRenewal =
                  res.data && res.data.data && res.data.data.nextRenewal
                    ? res.data.data.nextRenewal
                    : "";
              }
              return c;
            })
          );
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setError(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    }
  };

  const handleTestimonialModalShow = (id, test) => {
    setTestimonial(test);
    setSelectedUserByTestimonial(id);
    setTestimonialModal(true);
  };

  const handleAddInternalNoteClick = (id, internalNote) => {
    setSelectedUserByNote(id);
    setOldInternalNote(internalNote);
    setInternalNoteModal(true);
  };

  const handleReturnPackageClick = (id) => {
    setSelectedSubscriptionForReturnPackage(id);
    setReturnPackageModal(true);
  };

  const renderFilters = () => {
    return (
      <>
        <div className="filterContainer flex-row">
          {currentUser && currentUser.type == "admin" ? (
            <Col lg={2} className="p-0">
              <label className="filterLabelInput" htmlFor="branch">
                Branch
              </label>

              <select
                className="form-control filterInput2"
                value={branchFilter}
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleBranchChange(e.target.value)}
              >
                <option value="" key="0">
                  All
                </option>
                {branches.map((branch, index) => (
                  <option key={index} value={branch._id}>
                    {branch.label}
                  </option>
                ))}
              </select>
            </Col>
          ) : null}

          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="type">
              Client
            </label>
            <input
              type="text"
              className="form-control filterInput2"
              name="keywordFilter"
              value={keywordFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleKeywordChange(e.target.value)}
            />
          </Col>
          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="subTypeFilter">
              Subscription Type
            </label>
            <select
              className="form-control filterInput2"
              name="subTypeFilter"
              placeholder="Subscription Type"
              value={subTypeFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleSubTypeChange(e.target.value)}
            >
              <option value="">All</option>
              {options &&
                options.userSubscribeType.map((item, index) => (
                  <option key={"subType" + index} value={item.key}>
                    {item.value}
                  </option>
                ))}
            </select>
          </Col>
          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="planFilter">
              Plan
            </label>
            <select
              name="planFilter"
              className="form-control filterInput2"
              value={planFilter}
              onChange={(e) => handlePlanChange(e.target.value)}
            >
              <option value=""></option>
              {planList && planList.length > 0
                ? planList.map((plan, index) => (
                    <option key={index} value={plan._id}>
                      {plan.label}
                    </option>
                  ))
                : null}
            </select>
          </Col>
        </div>
        <div className="filterContainer flex-row">
          <Col lg={2} className="p-0">
            <label className="filterLabelInput" htmlFor="sourceFilter">
              Source
            </label>
            <select
              className="form-control filterInput2"
              value={sourceFilter}
              onChange={(e) => handleSourceChange(e.target.value)}
            >
              <option value="" key="0">
                All
              </option>
              {options.source_register.map((s, index) => (
                <option key={index} value={s.key}>
                  {s.value}
                </option>
              ))}
            </select>
          </Col>

          <Col lg={2} className="p-0">
            <label htmlFor="start" className="filterLabelInput">
              From
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="dd-MM-yyyy"
              className="form-control filterInput2"
              name="start"
              selected={start}
              onChange={(date) => setStart(date)}
            />
          </Col>
          <Col lg={2} className="p-0">
            <label htmlFor="to" className="filterLabelInput">
              To
            </label>
            <DatePicker
              autoComplete="off"
              dateFormat="dd-MM-yyyy"
              className="form-control filterInput2"
              name="end"
              selected={end}
              onChange={(date) => setEnd(date)}
            />
          </Col>
          <Col lg={3} className="p-0">
            <label className="filterLabelInput" htmlFor="type">
              Bike/Charger/Battery
            </label>
            <input
              type="text"
              className="form-control filterInput2"
              name="invFilter"
              value={invFilter}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(e) => handleInvChange(e.target.value)}
            />
          </Col>

          <Col lg={2} className="p-0">
            <div style={{ marginTop: 35 }} border="0">
              <div className="d-flex flex-row align-items-start">
                <button
                  onClick={(e) => triggerSearch(e)}
                  className="btn btn-primary search-button"
                >
                  Go
                </button>
                <button
                  onClick={(e) => resetSearch(e)}
                  className="btn btn-primary reset-button"
                >
                  Reset
                </button>
              </div>
            </div>
          </Col>
        </div>
      </>
    );
  };

  // Handles individual card toggle
  const onToggleCard = (id, expanded) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [id]: expanded,
    }));
  };

  // Expand all cards
  const handleExpandAll = () => {
    const newExpandedState = clients.reduce((acc, user) => {
      acc[user.id] = true; // Set all cards to expanded
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to expanded for all
  };

  // Collapse all cards
  const handleCollapseAll = () => {
    const newExpandedState = clients.reduce((acc, user) => {
      acc[user.id] = false; // Set all cards to collapsed
      return acc;
    }, {});
    setExpandedCards(newExpandedState); // Update state to collapsed for all
  };

  const renderUserCard = (u) => {
    const isExpanded = expandedCards[u.id] || false;
    const header = (
      <>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {/* Header row with expand icon, name, and InternalNoteComponent */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {/* First Name and Last Name */}
              <NavLink to={"/user/" + u.id} className="text-dark">
                {u.first_name} {u.last_name}
              </NavLink>
              {/* Action Icons row */}

              {u.usersubscribe ? (
                <>
                  <NavLink
                    to={"/subscription/" + u.usersubscribe._id}
                    className="text-dark midText"
                    style={{ marginLeft: "10px" }}
                  >
                    <i alt="View" title="View" className="fa fa-binoculars"></i>
                  </NavLink>
                </>
              ) : null}

              {u.usersubscribe && !u.usersubscribe.draft ? (
                <>
                  <a
                    href="#!"
                    onClick={() =>
                      createInvoice(
                        u.usersubscribe._id,
                        u.usersubscribe.invoice ? true : false
                      )
                    }
                    className="text-dark midText"
                    style={{ marginLeft: "10px" }}
                  >
                    <i
                      alt="Create Invoice"
                      title="Create Invoice"
                      className="fa fa-file"
                    ></i>
                  </a>
                </>
              ) : null}

              {u.usersubscribe &&
              u.usersubscribe !== undefined &&
              u.usersubscribe.packageReturned !== true ? (
                <>
                  &nbsp;&nbsp;
                  <NavLink
                    to={"subscription/edit/" + u.usersubscribe._id}
                    className="text-dark midText"
                    style={{ marginLeft: "5px" }}
                  >
                    <i alt="Edit" title="Edit" className="fa fa-pen"></i>
                  </NavLink>
                </>
              ) : null}
            </div>

            {/* InternalNoteComponent aligned to the right */}
            <InternalNoteComponent
              list={clients}
              setList={setClients}
              oldInternalNote={u.internalNote}
              selectedUserByNote={u.id}
              clickable={false}
              note={
                u.usersubscribe && u.usersubscribe.note
                  ? u.usersubscribe.note
                  : ""
              }
              rentedAcc={
                u.usersubscribe && u.usersubscribe.rentedAcc
                  ? u.usersubscribe.rentedAcc
                  : ""
              }
              style={{ marginLeft: "20px" }} // Optional spacing between name and InternalNoteComponent
            />
          </div>
        </div>
      </>
    );

    const body = (
      <>
        {u.usersubscribe &&
        u.usersubscribe.terminated &&
        u.usersubscribe.packageReturned !== true ? (
          <>
            {" "}
            <button
              className="linkBtn text-dark small-link midText"
              onClick={() => handleReturnPackageClick(u.usersubscribe._id)}
            >
              Return Package
            </button>
            <br />
          </>
        ) : null}
        {u.usersubscribe && u.usersubscribe.isFrozen ? (
          <>
            {" "}
            <UnfreezeSubscriptionButton
              id={u.usersubscribe._id}
              name={u.full_name}
            />
            <br />{" "}
            <ForceTerminate
              id={u.usersubscribe._id}
              name={u.full_name}
              list={clients}
              setList={setClients}
              isClients={true}
            />
          </>
        ) : (
          ""
        )}
        {u.usersubscribe &&
        u.usersubscribe.terminated &&
        u.usersubscribe.packageReturned !== true ? (
          <>
            <button
              className="linkBtn red text-dark small-link midText"
              onClick={() => activateSubscription(u.usersubscribe._id)}
            >
              Activate Subscription
            </button>
            <br />
          </>
        ) : u.usersubscribe &&
          u.usersubscribe.invoice &&
          !u.usersubscribe.terminated ? (
          <>
            <button
              className="linkBtn red text-dark small-link midText"
              onClick={() => handleReasonModalShow(u.usersubscribe._id)}
            >
              Terminate Subscription
            </button>
            <br />
          </>
        ) : null}

        <button
          className="linkBtn greenLink mx-0"
          onClick={() => handleTestimonialModalShow(u._id, u.testimonial)}
        >
          Testimonial
        </button>
        <br />
        <button
          className="linkBtn greenLink mx-0"
          onClick={() => handleAddInternalNoteClick(u._id, u.internalNote)}
        >
          Add an internal note
        </button>
        <br />
        {currentUser.type === "admin" ? (
          <>
            <span className="tinyCardLabel">Branch:&nbsp;</span>
            <span className="midText">{u.branch ? u.branch.label : ""}</span>
            <br />
          </>
        ) : (
          ""
        )}
        {u.type === "registered" || u.type === "subscriber" ? (
          <>
            <span className="tinyCardLabel">Source:&nbsp;</span>
            <span className="midText">{u.source ? u.source : "Unknown"}</span>
            <br />
          </>
        ) : (
          ""
        )}
        <span className="tinyCardLabel">Invoice:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe && u.usersubscribe.invoice ? (
            <button
              onClick={() =>
                viewPDF(
                  u.usersubscribe.invoice._id,
                  u.usersubscribe.invoice.fileName
                )
              }
              className="linkBtn text-dark "
            >
              {u.usersubscribe.invoice.number}
            </button>
          ) : (
            "None"
          )}
        </span>
        <br />
        <span className="tinyCardLabel">Plan:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe && u.usersubscribe.plan && u.usersubscribe.plan.label
            ? u.usersubscribe.plan.label
            : ""}
        </span>
        <br />
        <span className="tinyCardLabel">Payment Method:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe && u.usersubscribe.invoice
            ? u.usersubscribe.invoice.paymentMethod
            : ""}
        </span>
        <br />
        <span className="tinyCardLabel">Payment Status:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe &&
          u.usersubscribe.invoice &&
          u.usersubscribe.invoice.paid === true
            ? "Paid"
            : "Pending"}
        </span>
        <br />
        <span className="tinyCardLabel">Bike:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe && u.usersubscribe.bike
            ? u.usersubscribe.bike.number
            : ""}
        </span>
        <br />
        <span className="tinyCardLabel">Charger:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe && u.usersubscribe.charger
            ? u.usersubscribe.charger.number
            : ""}
        </span>
        <br />
        <span className="tinyCardLabel">Battery:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe && u.usersubscribe.battery
            ? u.usersubscribe.battery.number
            : ""}
        </span>
        <br />
        {u.usersubscribe &&
        u.usersubscribe.invoice &&
        u.usersubscribe.invoice.start &&
        u.usersubscribe.invoice.end ? (
          <>
            <span className="tinyCardLabel">Start:&nbsp;</span>
            <span className="midText">
              {DateFormatter.DateFormatterOne(u.usersubscribe.invoice.start)}
            </span>
            <br />
            {u.usersubscribe.plan &&
            (u.usersubscribe.plan.planType === "daily" ||
              u.usersubscribe.plan.planType === "hourly") ? (
              <>
                <span className="tinyCardLabel">Time:&nbsp;</span>
                <span className="midText">{u.usersubscribe.startTime}</span>
              </>
            ) : (
              <>
                <span className="tinyCardLabel">End:&nbsp;</span>
                <span className="midText">
                  {DateFormatter.DateFormatterOne(u.usersubscribe.invoice.end)}
                </span>
              </>
            )}
            <br />
          </>
        ) : (
          ""
        )}

        {u.usersubscribe?.plan &&
        (!u.usersubscribe.plan.planType ||
          u.usersubscribe.plan.planType == "monthly") ? (
          <>
            <span className="tinyCardLabel">Next Renewal:&nbsp;</span>
            <span className="midText">
              {u.usersubscribe &&
              u.usersubscribe.nextRenewal &&
              u.usersubscribe.nextRenewal != "None" &&
              u.usersubscribe.nextRenewal !== ""
                ? DateFormatter.DateFormatterOne(u.usersubscribe.nextRenewal)
                : ""}
            </span>
            <br />
          </>
        ) : (
          ""
        )}
        <span className="tinyCardLabel">Fee:&nbsp;</span>
        <span className="midText">
          {u.usersubscribe &&
          u.usersubscribe.invoice &&
          u.usersubscribe.invoice.type === "Refund"
            ? "-"
            : null}
          {u.usersubscribe &&
          u.usersubscribe.invoice &&
          u.usersubscribe.invoice.paymentMethod !== "Cash-USD" &&
          u.usersubscribe.invoice.currency === "USD"
            ? commonFunctions.CommaFormatted(
                u.usersubscribe.invoice.total_amount *
                  u.usersubscribe.invoice.currencyRate
              ) +
              " " +
              u.branch.currency
            : u.usersubscribe && u.usersubscribe.invoice
            ? commonFunctions.CommaFormatted(
                u.usersubscribe.invoice.total_amount
              ) +
              " " +
              u.usersubscribe.invoice.currency
            : ""}
        </span>
        <br />

        <span className="tinyCardLabel">Phone:&nbsp;</span>
        <span className="midText">
          {u.phone && u.phone[0] === "0" && u.phone[1] === "0" ? (
            <span>+{u.phone.slice(2)}</span>
          ) : u.phone && u.phone[0] != "+" ? (
            <span>
              <span>+</span>
              {u.phone &&
              u.phone[0] !== "9" &&
              u.phone[1] !== "6" &&
              u.phone[2] !== "1" ? (
                <span>961</span>
              ) : null}
              <span>{u.phone}</span>
            </span>
          ) : (
            <span>{u.phone}</span>
          )}
        </span>
        <br />
      </>
    );
    return (
      <ExpandableCard
        header={header}
        body={body}
        onToggleCard={onToggleCard}
        isExpanded={isExpanded}
        id={u.id}
      />
    );
  };

  return (
    <div className="container">
      <ReturnPackageModal
        returnPackageModal={returnPackageModal}
        setReturnPackageModal={setReturnPackageModal}
        selectedSubscriptionForReturnPackage={
          selectedSubscriptionForReturnPackage
        }
        list={clients}
        setList={setClients}
        isClients
      />
      <InternalNoteModal
        internalNoteModal={internalNoteModal}
        setInternalNoteModal={setInternalNoteModal}
        selectedUserByNote={selectedUserByNote}
        oldInternalNote={oldInternalNote}
        list={clients}
        setList={setClients}
      />
      <TestimonialModal
        testimonialModal={testimonialModal}
        setTestimonialModal={setTestimonialModal}
        selectedUserByTestimonial={selectedUserByTestimonial}
        setSelectedUserByTestimonial={setSelectedUserByTestimonial}
        list={clients}
        setList={setClients}
        oldTestimonial={testimonial}
      />
      <TerminationModal
        terminateSubscriptionModal={terminateSubscriptionModal}
        handleTerminateSubscriptionModalClose={
          handleTerminateSubscriptionModalClose
        }
        reason={reason}
        setReason={setReason}
        sendReasonHandler={sendReasonHandler}
        terminationCategory={terminationCategory}
        setTerminationCategory={setTerminationCategory}
        categories={categories}
      />

      {error ? (
        <header className="jumbotron">
          {" "}
          <h3>{error}</h3>{" "}
        </header>
      ) : null}

      <div>
        <h3> Wavers List </h3>
        {renderFilters()}
        {!error && !loading && clients.length != 0 ? (
          <div>
            <Row>
              <Col>
                <span style={{ float: "right", marginLeft: 10 }}>
                  {total} results
                </span>
                <span style={{ float: "left", marginRight: 10 }}>
                  <a
                    className="greenLink mx-0"
                    href="javascript:void(0)"
                    onClick={handleExpandAll}
                  >
                    Expand All
                  </a>{" "}
                  <a
                    className="greenLink mx-0"
                    href="javascript:void(0)"
                    onClick={handleCollapseAll}
                  >
                    Collapse All
                  </a>
                </span>
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "first baseline",
              }}
            >
              {clients.map((u) => renderUserCard(u))}
            </Row>

            <div className="mt-3">
              {"Items per Page: "}
              <select
                onChange={(e) => handlePageSizeChange(e.target.value)}
                value={itemsPerPage}
              >
                {pageSizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <Pagination
                className="my-3"
                count={count}
                page={page}
                siblingtotal={1}
                boundarytotal={1}
                variant="outlined"
                color="primary"
                shape="rounded"
                onChange={handlePageChange}
                size="medium"
              />
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : resetMsg != "" ? (
          <div className="defaultMsg">{resetMsg}</div>
        ) : (
          <div className="noResultsFound">{labels.NO_RESULTS}</div>
        )}
      </div>
    </div>
  );
};

export default Clients;
